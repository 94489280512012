import { TherapistFile } from "types/therapist.types";

export const getAvatar = (
  files: readonly TherapistFile[]
): TherapistFile | undefined => {
  const photos = files.filter(
    (f) => f.mime?.match("image") && f.type === "photo_and_video_intro"
  );

  let photoWithMinimalId = photos[0];

  for (let i = 0; i < photos.length; i += 1) {
    if (photos[i].is_main_photo) {
      return photos[i];
    }
    if (photos[i].id < photoWithMinimalId.id) {
      photoWithMinimalId = photos[i];
    }
  }

  return photoWithMinimalId;
};

export const stateLicenseList = <T>(
  data: readonly T[],
  field?: keyof T
): string => {
  if (data.length > 0) {
    const array = [...data];
    return array.reduce((acc, el) => {
      const value = field && typeof el?.[field] === "string" ? el?.[field] : el;
      if (String(value).length > 0) {
        return acc.length ? `${acc}, ${value}` : `${acc}${value}`;
      }
      return acc;
    }, "");
  }

  return "";
};

import {
  NO_LICENSE_LABEL,
  NO_LICENSE_ORGANIZATION_LABEL,
  NO_LICENSE_ORGANIZATION_NUMBER,
  NO_LICENSE_YEAR,
  NUMBER_FIELD,
  NUMBER_LABEL,
  ORGANIZATION_FIELD,
  ORGANIZATION_LABEL,
  STATE_LICENSE_LABEL,
} from "constants/fields";
import { AGE_ICONS, PAYMENT_ICONS, THERAPIST_ICONS } from "constants/icons";
import { FAITH_OPTIONS, states } from "constants/options";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { ReactComponent as IconZoom } from "assets/icons/zoom.svg";
import cn from "classnames";
import { FormatProfileField } from "components/FormatProfileField";
import { ImagePreview } from "components/ImagePreview";
import { Card } from "components/UIKit/Card";
import { ModalityList } from "components/UIKit/ModalityList";
import { Tab, TabList, TabPanel, Tabs } from "components/UIKit/Tabs";
import { Player } from "components/Video/Player";
import { useModal } from "hooks/useModal";
import { useResponsive } from "hooks/useResponsive";
import { capitalize, startCase } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { credentialService } from "services/credentialService";
import { DictionaryItem, dictionaryService } from "services/dictionaryService";
import { educationService } from "services/educationService";
import { stateLicenseService } from "services/stateLicenseService";
import { therapistProfileService } from "services/therapistProfileService";
import { Credential } from "types/credential.types";
import { Education } from "types/education.types";
import { StateLicense } from "types/stateLicense.types";
import { TherapistFile } from "types/therapist.types";
import { filterDictionaryItems } from "utils/filterDictionaryItems";
import { findOption } from "utils/findOption";
import { formatCurrency } from "utils/formatCurrency";
import { getWebsiteAddress } from "utils/getWebsiteAddress";
import { isEmpty } from "utils/isEmpty";
import { pluralize } from "utils/pluralize";
import { useSnapshot } from "valtio";
import { AgeCard } from "../../blocks/AgeCard/AgeCard";
import { VerifiedCircle } from "../../blocks/VerifiedCircle/VerifiedCircle";
import { TherapistMapList } from "../TherapistMapList";
import { TabItemBlock } from "./blocks/TabItemBlock";
import { PRIMARY_CREDENTIAL_TYPE } from "./constants";
import styles from "./PatientsTherapistTabs.module.scss";
import "./PatientsTherapistTabs.scss";

export const PatientsTherapistTabs = () => {
  const { id } = useParams();
  const isMobile = useResponsive("layout-flip");
  const { open, addProperties } = useModal("ZOOM_AVATAR");
  const [specialties, setSpecialties] = useState<DictionaryItem[]>([]);
  const [languages, setLanguages] = useState<DictionaryItem[]>([]);
  const [alliedGroups, setAlliedGroups] = useState<DictionaryItem[]>([]);
  const [insuranceCompanies, setInsuranceCompanies] =
    useState<DictionaryItem[]>();
  const [therapies, setTherapies] = useState<DictionaryItem[]>([]);
  const [stateLicense, setStateLicense] = useState<StateLicense[]>([]);
  const [education, setEducation] = useState<Education[]>([]);
  const [otherCredential, setOtherCredential] = useState<Credential[]>([]);
  const { currentProfile } = useSnapshot(therapistProfileService);

  useEffect(() => {
    (async () => {
      const [
        /* eslint-disable @typescript-eslint/naming-convention */
        _specialties,
        _languages,
        _alliedGroups,
        _insuranceCompanies,
        _therapies,
        /* eslint-enable @typescript-eslint/naming-convention */
        stateLicenses,
        educations,
        otherCredentials,
      ] = await Promise.all([
        dictionaryService.getSpecialties(),
        dictionaryService.getLanguages(),
        dictionaryService.getAlliedGroups(),
        dictionaryService.getInsuranceCompanies(),
        dictionaryService.getTherapies(),
        stateLicenseService.list(String(id), undefined, false),
        educationService.list(String(id), undefined, false),
        credentialService.list(String(id), undefined, false),
      ]);
      setSpecialties(
        filterDictionaryItems(_specialties, currentProfile?.specialties)
      );
      setLanguages(
        filterDictionaryItems(_languages, currentProfile?.preferred_languages)
      );
      setAlliedGroups(
        filterDictionaryItems(_alliedGroups, currentProfile?.allied_groups)
      );
      setInsuranceCompanies(
        filterDictionaryItems(
          _insuranceCompanies,
          currentProfile?.insurance_companies
        )
      );
      setTherapies(
        filterDictionaryItems(_therapies, currentProfile?.therapies)
      );
      setStateLicense(stateLicenses);
      setEducation(educations);
      setOtherCredential(otherCredentials);
    })();
  }, [
    setSpecialties,
    setLanguages,
    setAlliedGroups,
    setInsuranceCompanies,
    setTherapies,
    currentProfile?.specialties,
    currentProfile?.preferred_languages,
    currentProfile?.allied_groups,
    currentProfile?.therapies,
    currentProfile?.insurance_companies,
    id,
  ]);

  const onZoomPhoto = (file: TherapistFile) => {
    open();
    addProperties({ file });
  };

  if (currentProfile === null) return null;

  return (
    <Tabs className={`patients-therapist-tabs ${styles.root}`}>
      <TabList className="patients-therapist-tabs__row">
        <Tab className="patients-therapist-tabs__row-item">About</Tab>
        <Tab className="patients-therapist-tabs__row-item">Rates</Tab>
        <Tab className="patients-therapist-tabs__row-item">
          Experience & Credentials
        </Tab>
        {isMobile && (
          <Tab className="patients-therapist-tabs__row-item">Location</Tab>
        )}
      </TabList>
      <TabPanel>
        <div className="patients-therapist-tabs__info">
          <FormatProfileField
            shouldLimitLength={false}
            fallbackText="No description added yet"
          >
            {currentProfile.personal_statement_short_description}
          </FormatProfileField>
        </div>
        {currentProfile.video && (
          <Player
            className="patients-therapist-tabs__video"
            src={currentProfile.video.file}
          />
        )}

        <div className="patients-therapist-tabs__issues">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Issues />
            Specialties
          </p>
          <FormatProfileField
            fallbackText="No issues"
            className="patients-therapist-tabs__text"
            objectToCheck={specialties}
          >
            <ul className="patients-therapist-tabs__issues-list">
              {specialties.length > 0 &&
                specialties.map((specialty) => (
                  <li
                    key={specialty.code}
                    className="patients-therapist-tabs__issues-item active"
                  >
                    {specialty.name}
                  </li>
                ))}
            </ul>
          </FormatProfileField>
        </div>
        <div
          className={cn(
            "patients-therapist-tabs__treatment",
            isEmpty(therapies) && "empty"
          )}
        >
          <p className="patients-therapist-tabs__treatment-title">
            {isMobile && <THERAPIST_ICONS.Inbox />}
            Treatment Preferences
          </p>

          <FormatProfileField
            className="patients-therapist-tabs__text"
            fallbackText="No treatment preferences"
            objectToCheck={therapies}
          >
            <ul className="patients-therapist-tabs__treatment-list">
              {therapies.map((therapy) => (
                <li
                  key={therapy.code}
                  className="patients-therapist-tabs__treatment-item"
                >
                  <VerifiedCircle text={therapy.name} />
                </li>
              ))}
            </ul>
          </FormatProfileField>
        </div>
        <div className="patients-therapist-tabs__age patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Client className="client" />
            Client Age
          </p>
          <FormatProfileField
            className="patients-therapist-tabs__text"
            fallbackText="No ages selected"
            objectToCheck={currentProfile.ages}
          >
            <ul className="patients-therapist-tabs__age-list">
              {AGE_ICONS.filter((icon) =>
                currentProfile.ages.includes(icon.value)
              ).map((icon) => (
                <li
                  key={icon.value}
                  className="patients-therapist-tabs__age-item"
                >
                  <AgeCard {...icon} />
                </li>
              ))}
            </ul>
          </FormatProfileField>
        </div>
        <div className="patients-therapist-tabs__photos patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Camera />
            Photos
          </p>
          <FormatProfileField
            className="patients-therapist-tabs__text"
            fallbackText="No photos"
            objectToCheck={currentProfile.photos}
          >
            <ul className={styles.photosList}>
              {currentProfile.photos.map((file) => (
                <li key={file.id} className={styles.photosItem}>
                  <img src={file.file} alt="Photos" />
                  <button
                    className={styles.icon}
                    type="button"
                    onClick={() => onZoomPhoto(file)}
                  >
                    <IconZoom />
                  </button>
                </li>
              ))}
            </ul>
          </FormatProfileField>
        </div>
        <div className="patients-therapist-tabs__modality patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Person />
            Modality
          </p>
          <FormatProfileField
            className="patients-therapist-tabs__text"
            fallbackText="No modalities"
            objectToCheck={currentProfile.modalities}
          >
            <ModalityList modalities={currentProfile.modalities} />
          </FormatProfileField>
        </div>
        <div className="patients-therapist-tabs__languages patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Globe />
            Languages
          </p>
          <p className="patients-therapist-tabs__text">
            {["English", ...languages.map((lang) => lang.name)].join(", ")}
          </p>
        </div>
        <div className="patients-therapist-tabs__faith patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Faith />
            Faith
          </p>
          <p className="patients-therapist-tabs__text">
            <FormatProfileField
              fallbackText="No preference"
              shouldLimitLength={false}
            >
              {
                FAITH_OPTIONS.find(
                  (option) => option.value === currentProfile.preferred_faith
                )?.label
              }
            </FormatProfileField>
          </p>
        </div>
        <div className="patients-therapist-tabs__familiar patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Groups />
            Familiar Groups
          </p>
          <FormatProfileField
            className="patients-therapist-tabs__text"
            fallbackText="No preference"
            objectToCheck={alliedGroups}
          >
            <p className="patients-therapist-tabs__familiar-groups patients-therapist-tabs__text">
              {alliedGroups.map((group) => group.name).join(", ")}
            </p>
          </FormatProfileField>
        </div>
        <div className="patients-therapist-tabs__insurance patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Shield />
            Insurance
          </p>
          <div className="patients-therapist-tabs__insurance-row patients-therapist-tabs__text">
            <b>Accepted Insurance Companies:</b>
            <div>
              <FormatProfileField shouldLimitLength={false}>
                <ul className="patients-therapist-tabs__insurance-companies">
                  {insuranceCompanies?.map(({ name, code }) => (
                    <li key={code}>{name}</li>
                  ))}
                </ul>
              </FormatProfileField>
            </div>
          </div>
          {currentProfile.npi_number && (
            <p className="patients-therapist-tabs__insurance-row patients-therapist-tabs__text">
              <b>NPI Number:</b>
              <span>
                <FormatProfileField>
                  {currentProfile.npi_number}
                </FormatProfileField>
              </span>
            </p>
          )}
          {currentProfile.malpractice_carrier && (
            <p className="patients-therapist-tabs__insurance-row patients-therapist-tabs__text">
              <b>Malpractice Carrier:</b>
              <span>
                <FormatProfileField>
                  {currentProfile.malpractice_carrier}
                </FormatProfileField>
              </span>
            </p>
          )}
          {currentProfile.membership_id && (
            <p className="patients-therapist-tabs__insurance-row patients-therapist-tabs__text">
              <b>Membership ID/Number:</b>
              <span>
                <FormatProfileField>
                  {currentProfile.membership_id}
                </FormatProfileField>
              </span>
            </p>
          )}
        </div>
        <div className="patients-therapist-tabs__website patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Browser />
            Website
          </p>
          <FormatProfileField
            className="patients-therapist-tabs__text"
            fallbackText="No website"
            shouldLimitLength={false}
          >
            {currentProfile?.website ? (
              <a
                href={getWebsiteAddress(currentProfile?.website)}
                target="_blank"
                rel="noreferrer"
                className="patients-therapist-tabs__website-link patients-therapist-tabs__text"
              >
                {currentProfile?.website}
              </a>
            ) : null}
          </FormatProfileField>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="patients-therapist-tabs__fees patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Wallet />
            Fees
          </p>
          <p className="patients-therapist-tabs__fees-row">
            <span>In-Person session</span>
            <FormatProfileField
              fallbackText="Not provided"
              shouldLimitLength={false}
            >
              {formatCurrency(currentProfile?.in_person_session_cost_min) ||
                "Not provided"}
            </FormatProfileField>
          </p>
          <p className="patients-therapist-tabs__fees-row">
            <span>Online session</span>
            <FormatProfileField
              fallbackText="Not provided"
              shouldLimitLength={false}
            >
              {formatCurrency(
                currentProfile?.online_individual_session_cost_min
              ) || "Not provided"}
            </FormatProfileField>
          </p>
        </div>
        <div className="patients-therapist-tabs__payment patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.CreditCard />
            Payment Methods
          </p>
          <FormatProfileField
            className="patients-therapist-tabs__text"
            fallbackText="No payment methods"
            objectToCheck={currentProfile?.form_of_payment}
          >
            <ul className="patients-therapist-tabs__payment-list">
              {currentProfile.form_of_payment
                .filter((method) => method in PAYMENT_ICONS)
                .map((method) => (
                  <li
                    key={method}
                    className="patients-therapist-tabs__payment-item"
                  >
                    <img
                      src={PAYMENT_ICONS[method]}
                      alt={method}
                      className={cn(
                        method === "cash" &&
                          "patients-therapist-tabs__payment-item__cash-icon"
                      )}
                    />
                    {method === "cash" && startCase(method)}
                  </li>
                ))}
            </ul>
          </FormatProfileField>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="patients-therapist-tabs__credentials patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.File />
            State Licensing
          </p>
          {stateLicense.length > 1
            ? stateLicense.map((license) => (
                <Card
                  className="patients-therapist-tabs__card"
                  key={license.id}
                  withAccordion
                  iconClassName={
                    license.state_license_type === "i_have_no_license" ||
                    license.is_verified
                      ? ""
                      : "no-verified"
                  }
                  accordionContent={
                    <div className="patients-therapist-tabs__accordion">
                      <TabItemBlock
                        label={`${
                          license.is_verified ? "Verified " : ""
                        }Credential:`}
                      >
                        {PRIMARY_CREDENTIAL_TYPE[license.state_license_type]}
                      </TabItemBlock>
                      <TabItemBlock label="Mental Health Role:">
                        {license.mental_health_role}
                      </TabItemBlock>
                      <TabItemBlock label="Supervisor Name:">
                        {license.supervisor_name &&
                          capitalize(license.supervisor_name)}
                      </TabItemBlock>
                      <TabItemBlock label="Supervisor License Number:">
                        {license.supervisor_license_number}
                      </TabItemBlock>
                      <TabItemBlock label="Supervisor License State:">
                        {license.supervisor_license_state &&
                          findOption(
                            license.supervisor_license_state,
                            states,
                            "label"
                          )}
                      </TabItemBlock>
                      <TabItemBlock label="Supervisor License Expiration:">
                        {license.supervisor_license_expiration}
                      </TabItemBlock>
                      <TabItemBlock label="License Number:">
                        {license.license_number}
                      </TabItemBlock>
                      <TabItemBlock label="License State:">
                        {license.license_state &&
                          findOption(license.license_state, states, "label")}
                      </TabItemBlock>
                      <TabItemBlock label="License Expiration:">
                        {license.license_expiration}
                      </TabItemBlock>
                      {license.no_license_type && (
                        <TabItemBlock label="Credential Type:">
                          {license.no_license_type &&
                            capitalize(license.no_license_type)}
                        </TabItemBlock>
                      )}
                      {license.no_license_type && (
                        <>
                          <TabItemBlock
                            label={`${
                              ORGANIZATION_LABEL[license.no_license_type]
                            }:`}
                          >
                            {license[
                              `no_license_${
                                ORGANIZATION_FIELD[license.no_license_type]
                              }`
                            ] &&
                              capitalize(
                                license[
                                  `no_license_${
                                    ORGANIZATION_FIELD[license.no_license_type]
                                  }`
                                ]
                              )}
                          </TabItemBlock>
                          <TabItemBlock
                            label={`${NUMBER_LABEL[license.no_license_type]}:`}
                          >
                            {license[
                              `no_license_${
                                NUMBER_FIELD[license.no_license_type]
                              }`
                            ] &&
                              capitalize(
                                license[
                                  `no_license_${
                                    NUMBER_FIELD[license.no_license_type]
                                  }`
                                ]
                              )}
                          </TabItemBlock>
                          <TabItemBlock label="Year:">
                            {license.no_license_year}
                          </TabItemBlock>
                        </>
                      )}
                      {currentProfile.credentialFiles.length !== 0 && (
                        <div className="patients-therapist-tabs__credentials-images">
                          {license?.files &&
                            license?.files.map((file) => (
                              <ImagePreview
                                key={file.id}
                                file={file}
                                alt="Certificate"
                                className="patients-therapist-tabs__credentials-img"
                                withZoom={false}
                              />
                            ))}
                        </div>
                      )}
                    </div>
                  }
                  values={[
                    {
                      caption: `${
                        license.is_verified ? "VERIFIED " : ""
                      }CREDENTIAL`,
                      text: (
                        <FormatProfileField maxLength={30}>
                          {license.no_license_type &&
                          license.state_license_type === "i_have_no_license"
                            ? NO_LICENSE_LABEL[license.no_license_type]
                            : STATE_LICENSE_LABEL[license.state_license_type]}
                        </FormatProfileField>
                      ),
                    },
                    ...(license.no_license_type &&
                    license.state_license_type === "i_have_no_license"
                      ? [
                          {
                            caption:
                              NO_LICENSE_ORGANIZATION_LABEL[
                                license.no_license_type
                              ],
                            text: (
                              <FormatProfileField>
                                {
                                  license[
                                    `no_license_${
                                      ORGANIZATION_FIELD[
                                        license.no_license_type
                                      ]
                                    }`
                                  ]
                                }
                              </FormatProfileField>
                            ),
                          },
                          {
                            caption:
                              NO_LICENSE_ORGANIZATION_NUMBER[
                                license.no_license_type
                              ],
                            text: (
                              <FormatProfileField>
                                {
                                  license[
                                    `no_license_${
                                      NUMBER_FIELD[license.no_license_type]
                                    }`
                                  ]
                                }
                              </FormatProfileField>
                            ),
                          },
                          {
                            caption: NO_LICENSE_YEAR[license.no_license_type],
                            text: (
                              <FormatProfileField>
                                {license.no_license_year}
                              </FormatProfileField>
                            ),
                          },
                        ]
                      : []),
                    ...(license.state_license_type === "i_am_pre_licensed"
                      ? [
                          {
                            caption: "SUPERVISOR’S LICENSE",
                            text: (
                              <FormatProfileField>
                                {license.supervisor_license_number}
                              </FormatProfileField>
                            ),
                          },
                          {
                            caption: "SUPERVISOR’S STATE",
                            text: (
                              <FormatProfileField>
                                {license.supervisor_license_state}
                              </FormatProfileField>
                            ),
                          },
                          {
                            caption: "EXPIRATION",
                            text: (
                              <FormatProfileField>
                                {license.supervisor_license_expiration}
                              </FormatProfileField>
                            ),
                          },
                        ]
                      : []),
                    ...(license.state_license_type === "i_am_licensed"
                      ? [
                          {
                            caption: "LICENSE NUMBER",
                            text: (
                              <FormatProfileField>
                                {license.license_number}
                              </FormatProfileField>
                            ),
                          },
                          {
                            caption: "LICENSE STATE",
                            text: (
                              <FormatProfileField>
                                {license.license_state}
                              </FormatProfileField>
                            ),
                          },
                          {
                            caption: "EXPIRATION",
                            text: (
                              <FormatProfileField>
                                {license.license_expiration}
                              </FormatProfileField>
                            ),
                          },
                        ]
                      : []),
                  ]}
                  icon={<DocumentIcon />}
                />
              ))
            : stateLicense[0] && (
                <>
                  <TabItemBlock
                    label={`${
                      stateLicense[0].is_verified ? "Verified " : ""
                    }Credential:`}
                  >
                    {
                      PRIMARY_CREDENTIAL_TYPE[
                        stateLicense[0].state_license_type
                      ]
                    }
                  </TabItemBlock>
                  <TabItemBlock label="Mental Health Role:">
                    {stateLicense[0].mental_health_role}
                  </TabItemBlock>
                  <TabItemBlock label="Supervisor Name:">
                    {stateLicense[0].supervisor_name &&
                      capitalize(stateLicense[0].supervisor_name)}
                  </TabItemBlock>
                  <TabItemBlock label="Supervisor License Number:">
                    {stateLicense[0].supervisor_license_number}
                  </TabItemBlock>
                  <TabItemBlock label="Supervisor License State:">
                    {stateLicense[0].supervisor_license_state &&
                      findOption(
                        stateLicense[0].supervisor_license_state,
                        states,
                        "label"
                      )}
                  </TabItemBlock>
                  <TabItemBlock label="Supervisor License Expiration:">
                    {stateLicense[0].supervisor_license_expiration}
                  </TabItemBlock>
                  <TabItemBlock label="License Number:">
                    {stateLicense[0].license_number}
                  </TabItemBlock>
                  <TabItemBlock label="License State:">
                    {stateLicense[0].license_state &&
                      findOption(
                        stateLicense[0].license_state,
                        states,
                        "label"
                      )}
                  </TabItemBlock>
                  <TabItemBlock label="License Expiration:">
                    {stateLicense[0].license_expiration}
                  </TabItemBlock>
                  {stateLicense[0].no_license_type && (
                    <TabItemBlock label="Credential Type:">
                      {stateLicense[0].no_license_type &&
                        capitalize(stateLicense[0].no_license_type)}
                    </TabItemBlock>
                  )}
                  {stateLicense[0].no_license_type && (
                    <>
                      <TabItemBlock
                        label={`${
                          ORGANIZATION_LABEL[stateLicense[0].no_license_type]
                        }:`}
                      >
                        {stateLicense[0][
                          `no_license_${
                            ORGANIZATION_FIELD[stateLicense[0].no_license_type]
                          }`
                        ] &&
                          capitalize(
                            stateLicense[0][
                              `no_license_${
                                ORGANIZATION_FIELD[
                                  stateLicense[0].no_license_type
                                ]
                              }`
                            ]
                          )}
                      </TabItemBlock>
                      <TabItemBlock
                        label={`${
                          NUMBER_LABEL[stateLicense[0].no_license_type]
                        }:`}
                      >
                        {stateLicense[0][
                          `no_license_${
                            NUMBER_FIELD[stateLicense[0].no_license_type]
                          }`
                        ] &&
                          capitalize(
                            stateLicense[0][
                              `no_license_${
                                NUMBER_FIELD[stateLicense[0].no_license_type]
                              }`
                            ]
                          )}
                      </TabItemBlock>
                      <TabItemBlock label="Year:">
                        {stateLicense[0].no_license_year}
                      </TabItemBlock>
                    </>
                  )}
                  {currentProfile.credentialFiles.length !== 0 && (
                    <div className="patients-therapist-tabs__credentials-images">
                      {stateLicense[0]?.files &&
                        stateLicense[0]?.files.map((file) => (
                          <ImagePreview
                            key={file.id}
                            file={file}
                            alt="Certificate"
                            className="patients-therapist-tabs__credentials-img"
                          />
                        ))}
                    </div>
                  )}
                </>
              )}
        </div>
        <div className="patients-therapist-tabs__education patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Education />
            Education
          </p>
          {education.length > 1
            ? education.map((educ) => (
                <Card
                  key={educ.id}
                  withAccordion
                  icon={<DocumentIcon />}
                  values={[
                    {
                      caption: "SCHOOL GRADUATED",
                      text: (
                        <FormatProfileField>
                          {educ.school_graduated}
                        </FormatProfileField>
                      ),
                    },
                    {
                      caption: "DEGREE / DIPLOMA",
                      text: (
                        <FormatProfileField>
                          {educ.degree_diploma}
                        </FormatProfileField>
                      ),
                    },
                    {
                      caption: "YEAR GRADUATED",
                      text: (
                        <FormatProfileField>
                          {educ.year_graduated}
                        </FormatProfileField>
                      ),
                    },
                  ]}
                  accordionContent={
                    <div className="patients-therapist-tabs__accordion">
                      {educ.school_graduated && (
                        <p className="patients-therapist-tabs__subject">
                          School graduated:{" "}
                          <FormatProfileField shouldLimitLength={false}>
                            {educ.school_graduated}
                          </FormatProfileField>
                        </p>
                      )}
                      {educ.degree_diploma && (
                        <p className="patients-therapist-tabs__subject">
                          Degree / Diploma:{" "}
                          <FormatProfileField shouldLimitLength={false}>
                            {educ.degree_diploma}
                          </FormatProfileField>
                        </p>
                      )}
                      {educ.year_graduated && (
                        <p className="patients-therapist-tabs__subject">
                          Year graduated:{" "}
                          <FormatProfileField>
                            {educ.year_graduated}
                          </FormatProfileField>
                        </p>
                      )}
                    </div>
                  }
                />
              ))
            : education[0] && (
                <>
                  {education[0].school_graduated && (
                    <p className="patients-therapist-tabs__subject">
                      School graduated:{" "}
                      <FormatProfileField shouldLimitLength={false}>
                        {education[0].school_graduated}
                      </FormatProfileField>
                    </p>
                  )}
                  {education[0].degree_diploma && (
                    <p className="patients-therapist-tabs__subject">
                      Degree / Diploma:{" "}
                      <FormatProfileField>
                        {education[0].degree_diploma}
                      </FormatProfileField>
                    </p>
                  )}
                  {education[0].year_graduated && (
                    <p className="patients-therapist-tabs__subject">
                      Year graduated:{" "}
                      <FormatProfileField>
                        {education[0].year_graduated}
                      </FormatProfileField>
                    </p>
                  )}
                </>
              )}
        </div>
        <div className="patients-therapist-tabs__experience patients-therapist-tabs__border">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.Case />
            Experience
          </p>
          <p className="patients-therapist-tabs__subject">
            Years of experience: {currentProfile.how_long_practicing}{" "}
            {pluralize(currentProfile.how_long_practicing, "year", "years")}
          </p>
          {currentProfile.about_qualifications && (
            <p className="patients-therapist-tabs__subject">
              <FormatProfileField
                className="patients-therapist-tabs__subject"
                fallbackText="No additional information"
                shouldLimitLength={false}
              >
                {currentProfile.about_qualifications}
              </FormatProfileField>
            </p>
          )}
        </div>
        <div className="patients-therapist-tabs__education">
          <p className="patients-therapist-tabs__title">
            <THERAPIST_ICONS.File />
            Other Credentials
          </p>
          {otherCredential.length > 1
            ? otherCredential.map((credential) => (
                <Card
                  key={credential.id}
                  withAccordion
                  icon={<DocumentIcon />}
                  values={[
                    {
                      caption: "CREDENTIAL TYPE",
                      text: (
                        <FormatProfileField>
                          {ORGANIZATION_LABEL[credential.type]}
                        </FormatProfileField>
                      ),
                    },
                    {
                      caption: ORGANIZATION_LABEL[credential.type],
                      text: (
                        <FormatProfileField>
                          {credential[ORGANIZATION_FIELD[credential.type]]}
                        </FormatProfileField>
                      ),
                    },
                    {
                      caption: NUMBER_LABEL[credential.type],
                      text: (
                        <FormatProfileField>
                          {credential[NUMBER_FIELD[credential.type]]}
                        </FormatProfileField>
                      ),
                    },
                    {
                      caption: "YEAR",
                      text: (
                        <FormatProfileField>
                          {credential.year}
                        </FormatProfileField>
                      ),
                    },
                  ]}
                  accordionContent={
                    <div className="patients-therapist-tabs__accordion">
                      <TabItemBlock label={`Credential Type:`}>
                        {ORGANIZATION_LABEL[credential.type]}
                      </TabItemBlock>
                      <TabItemBlock
                        label={`${ORGANIZATION_LABEL[credential.type]}:`}
                      >
                        {credential[ORGANIZATION_FIELD[credential.type]] &&
                          capitalize(
                            credential[ORGANIZATION_FIELD[credential.type]]
                          )}
                      </TabItemBlock>
                      <TabItemBlock label={`${NUMBER_LABEL[credential.type]}:`}>
                        {credential[NUMBER_FIELD[credential.type]] &&
                          capitalize(credential[NUMBER_FIELD[credential.type]])}
                      </TabItemBlock>
                      <TabItemBlock label="Year:">
                        {credential.year}
                      </TabItemBlock>
                    </div>
                  }
                />
              ))
            : otherCredential[0] && (
                <>
                  <TabItemBlock label={`Credential Type:`}>
                    {ORGANIZATION_LABEL[otherCredential[0].type]}
                  </TabItemBlock>
                  <TabItemBlock
                    label={`${ORGANIZATION_LABEL[otherCredential[0].type]}:`}
                  >
                    {otherCredential[0][
                      ORGANIZATION_FIELD[otherCredential[0].type]
                    ] &&
                      capitalize(
                        otherCredential[0][
                          ORGANIZATION_FIELD[otherCredential[0].type]
                        ]
                      )}
                  </TabItemBlock>
                  <TabItemBlock
                    label={`${NUMBER_LABEL[otherCredential[0].type]}:`}
                  >
                    {otherCredential[0][
                      NUMBER_FIELD[otherCredential[0].type]
                    ] &&
                      capitalize(
                        otherCredential[0][
                          NUMBER_FIELD[otherCredential[0].type]
                        ]
                      )}
                  </TabItemBlock>
                  <TabItemBlock label="Year:">
                    {otherCredential[0].year}
                  </TabItemBlock>
                </>
              )}
        </div>
      </TabPanel>
      {isMobile && (
        <TabPanel>
          <TherapistMapList />
        </TabPanel>
      )}
    </Tabs>
  );
};

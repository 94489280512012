import cn from "classnames";
import { SimpleHeader as DefaultHeader } from "components/Header/SimpleHeader";
import { ReactNode } from "react";
import { FCWithChildren } from "types/react.types";
import styles from "./Layout.module.scss";

interface Props {
  containerClassName?: string;
  mainClassName?: string;
  header?: ReactNode;
  hasHeader?: boolean;
}

export const Layout: FCWithChildren<Props> = ({
  children,
  containerClassName,
  mainClassName,
  header = <DefaultHeader />,
  hasHeader = true,
}) => {
  return (
    <div className={cn(containerClassName, styles.layout)}>
      {hasHeader && header}
      <main className={cn(mainClassName, styles.main)}>{children}</main>
    </div>
  );
};

import { MODALITY_ICONS } from "constants/icons";
import cn from "classnames";
import { FC } from "react";
import { Modality } from "./components/Modality/Modality";
import styles from "./ModalityList.module.scss";

interface Props {
  modalities: readonly ("individuals" | "couples" | "family" | "group")[];
  className?: string;
  itemClassName?: string;
}

export const ModalityList: FC<Props> = ({
  modalities,
  className,
  itemClassName,
}) => {
  return (
    <ul className={cn(styles.modalityList, className)}>
      {MODALITY_ICONS.filter((icon) => modalities.includes(icon.value)).map(
        (icon) => (
          <li key={icon.value} className={styles.modalityListItem}>
            <Modality {...icon} className={itemClassName} />
          </li>
        )
      )}
    </ul>
  );
};

/* eslint-disable no-param-reassign, @typescript-eslint/ban-ts-comment */
import { useDelayedFn } from "hooks/useDelayedFunc";
import { useMountedState } from "hooks/useMountedState";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { AbstractFunc } from "types/utils.types";
import { getUpd } from "utils/getUpd";
import useVideoEffect, { VideoRef } from "../videoCommon";
import { UPD_RATE, VideoData } from "./util";

function useThrottledState<T>(
  initialValue: T,
  updRate: number
): {
  val: T;
  respondToEvent: AbstractFunc;
  forceNewState: Dispatch<SetStateAction<T>>;
} {
  const [state, setState] = useMountedState<T>(initialValue);
  const respondToEvent = useDelayedFn(setState, updRate, "throttle");

  return { val: state, respondToEvent, forceNewState: setState };
}

function useCurrentTime(
  videoRef: VideoRef,
  updRate: number = UPD_RATE
): VideoData<number> {
  const { val, respondToEvent, forceNewState } = useThrottledState<number>(
    0,
    updRate
  );

  useVideoEffect(videoRef, (video) => {
    forceNewState(video.currentTime);
    video.ontimeupdate = () => respondToEvent(video.currentTime);

    return () => {
      video.ontimeupdate = null;
    };
  });

  function set(upd: SetStateAction<number>) {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    video.currentTime = getUpd<number>(upd, video.currentTime);
  }

  return { val, set };
}

function usePaused(videoRef: VideoRef): VideoData<boolean> {
  const [paused, setPaused] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const enqueued = useRef<boolean>(null);

  useVideoEffect(videoRef, (video) => {
    setPaused(video.paused);
    video.onpause = () => setPaused(true);
    video.onplay = () => setPaused(false);

    return () => {
      video.onpause = null;
      video.onplay = null;
    };
  });

  function change(shouldBePaused: boolean) {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    if (shouldBePaused && !video.paused) {
      video.pause();
    } else if (!shouldBePaused && video.paused) {
      setCanUpdate(false);
      video.play().then(() => setCanUpdate(true));
    }
  }

  useEffect(() => {
    if (canUpdate && enqueued.current != null) {
      change(enqueued.current);
      // @ts-ignore
      enqueued.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUpdate]);

  function set(upd: SetStateAction<boolean>) {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    const shouldBePaused = getUpd<boolean>(upd, video.paused);

    if (!canUpdate) {
      // @ts-ignore
      enqueued.current = shouldBePaused;
      return;
    }

    change(shouldBePaused);
  }

  return { val: paused, set };
}

function useTotalDuration(videoRef: VideoRef) {
  const [totalDuration, setTotalDuration] = useMountedState<number | null>(
    null
  );

  const changeDuration = useDelayedFn(setTotalDuration, 250, "debounce", false);
  useVideoEffect(videoRef, (video) => {
    video.ondurationchange = () => changeDuration(video.duration);

    return () => {
      video.ondurationchange = null;
    };
  });

  return totalDuration;
}

export { useCurrentTime, usePaused, useTotalDuration };

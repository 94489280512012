import cn from "classnames";
import React, { ReactNode } from "react";
import { VideoData } from "../util";
import PlayButton from "./PlayButton";
import ProgressBar from "./ProgressBar";
import styles from "./Controls.module.scss";

type Props = {
  pausedData: VideoData<boolean>;
  currentTimeData: VideoData<number>;
  totalDuration: number | null;
  adornment?: ReactNode;
  className?: string;
};

export function Controls({
  pausedData,
  currentTimeData,
  totalDuration,
  adornment,
  className,
}: Props) {
  return (
    <div className={cn(styles.bottomPartWrapper, className)}>
      <div className={styles.controlsContainer}>
        <PlayButton pausedData={pausedData} />
        <ProgressBar
          currentTimeData={currentTimeData}
          totalDuration={totalDuration}
        />
      </div>
      {adornment}
    </div>
  );
}

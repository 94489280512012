import { BrowserHistory } from "history";
import { useLayoutEffect, useState, useEffect, FC } from "react";
import { Router, RouterProps } from "react-router-dom";
import { scrollToTop } from "utils/scrollToTop";

// in v6 of react-router we can't pass custom history object
// so this wrapper is workaround to pass history as in v5
// as result we can use `history.push()` outside components

interface CustomRouterProps
  extends Omit<RouterProps, "location" | "navigator"> {
  history: BrowserHistory;
}

export const CustomRouter: FC<CustomRouterProps> = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useEffect(() => {
    scrollToTop();
  }, [state]);

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

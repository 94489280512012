interface ItemWithValue {
  value: string;
}

export const findOption = <T extends ItemWithValue>(
  element: string,
  array: T[],
  key: keyof T
) => {
  const hasElement =
    element &&
    array.find(({ value }) =>
      value.toLowerCase().match(element.toLowerCase())
    )?.[key];

  return hasElement || element;
};

import { capitalize } from "lodash";
import { Title } from "types/therapist.types";

export const therapistName = ({
  title,
  first_name,
  middle_name,
  last_name,
  credentials_choice,
}: {
  title?: Title;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  credentials_choice?: string;
}) => {
  return [
    title && `${capitalize(title)}.`,
    first_name,
    middle_name && middle_name,
    last_name,
    credentials_choice && `(${credentials_choice})`,
  ].join(" ");
};

type Value = string | number | string[] | number[] | boolean;
type QueryParamValue = string | number | boolean;

export const objectToQueryParams = (params: Record<string, Value>) => {
  const queryArray: string[][] = [];
  const addToArray = (key: string, param: QueryParamValue) => {
    const checkedParam = typeof param === "string" ? param : param.toString();
    queryArray.push([key, checkedParam]);
  };

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((param) => addToArray(key, param));
    } else {
      addToArray(key, value);
    }
  });

  return queryArray;
};

import { useIsMounted } from "hooks/useIsMounted";
import { DependencyList, RefObject, useEffect } from "react";

export type VideoRef = RefObject<HTMLVideoElement>;

function useVideoEffect(
  videoRef: VideoRef,
  effect: (video: HTMLVideoElement) => void,
  extraDeps: DependencyList = []
) {
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!videoRef.current || !isMounted.current) {
      return;
    }

    return effect(videoRef.current);
  }, [videoRef, extraDeps, isMounted, effect]);
}

export default useVideoEffect;

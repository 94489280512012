import { ReactComponent as TickCircleRedIcon } from "assets/icons/tick-circle-red.svg";
import { HomeLayout } from "components/Layout/HomeLayout";
import { homePath } from "pages/Home";
import { Link } from "react-router-dom";
import styles from "./NotFound.module.scss";

export const notFoundPath = "/404";

export const NotFound = () => {
  return (
    <HomeLayout hasHeader={false} mainClassName={styles.notFoundPage}>
      <TickCircleRedIcon />
      <h1>Error 404: Page does not exist</h1>
      <Link to={homePath}>Go to home page</Link>
    </HomeLayout>
  );
};

import { proxy } from "valtio";

type ErrorData = {
  status: number;
  message?: string;
} | null;

class GeneralLogicService {
  error: ErrorData = null;

  setError(error: ErrorData) {
    this.error = error;
  }

  clearError() {
    this.setError(null);
  }
}

export const generalLogicService = proxy(new GeneralLogicService());

import cn from "classnames";
import { forwardRef, HTMLProps } from "react";
import styles from "./Radio.module.scss";

interface Props extends HTMLProps<HTMLInputElement> {
  label?: string;
}

export const Radio = forwardRef<HTMLInputElement, Props>(
  ({ className, label, checked, ...props }, ref) => {
    return (
      <label className={cn(className, styles.radio, checked && styles.checked)}>
        <input type="radio" ref={ref} checked={checked} {...props} />
        {label}
      </label>
    );
  }
);

import { SimpleHeader } from "components/Header";
import { Layout } from "components/Layout/Layout";
import { FCWithChildren } from "types/react.types";
import styles from "./HomeLayout.module.scss";

interface Props {
  hasHeader?: boolean;
  mainClassName?: string;
}

export const HomeLayout: FCWithChildren<Props> = ({
  children,
  hasHeader = true,
  mainClassName,
}) => (
  <Layout
    containerClassName={styles.homeLayout}
    mainClassName={mainClassName}
    hasHeader={hasHeader}
    header={<SimpleHeader />}
  >
    {children}
  </Layout>
);

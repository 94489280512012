import { TherapistCard } from "components/TherapistCard";
import styles from "pages/Connect/Success/Success.module.scss";
import { therapistProfileService } from "services/therapistProfileService";
import { useSnapshot } from "valtio";

export const successPath = "/success";

export const Success = () => {
  const { currentProfile } = useSnapshot(therapistProfileService);

  return (
    <div className={styles.successPage}>
      <h1 className={styles.title}>Finished!</h1>
      <p className={styles.subtitle}>
        Please have your phone nearby. Your ESA therapist is going to text you
        shortly.
      </p>

      {currentProfile && <TherapistCard therapist={currentProfile} />}
    </div>
  );
};

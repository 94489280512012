import { Education, EducationId } from "types/education.types";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";
import { TherapistCollectionService } from "./therapistCollectionService";

class EducationService extends TherapistCollectionService<
  EducationId,
  Education
> {
  constructor(props?: any) {
    super({ ...props, collectionString: "/educations" });
  }
}

export const educationService = proxy(new EducationService());

devtools(educationService, { name: "educationService" });

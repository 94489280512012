import { capitalize } from "lodash";
import { CredentialType } from "types/credential.types";
import {
  Credential,
  ALL_TITLES,
  Title,
  PrimaryCredentialType,
} from "types/therapist.types";

// Options are lists of choices that are static, not fetched; we know the codes (values) for them beforehand
export type ListOption<V = string> = { value: V; label: string };

export type AgeCode =
  | "toddler"
  | "child"
  | "preteen"
  | "teen"
  | "adult"
  | "adults"
  | "elder";
export type Ethnicity =
  | "none"
  | "native_american"
  | "hispanic"
  | "black"
  | "asian"
  | "pacific_islander"
  | "other";
export type Faith =
  | "none"
  | "christian"
  | "fundamentalist_christian"
  | "jewish"
  | "buddhist"
  | "church_of_jesus_christ"
  | "hindu"
  | "islam"
  | "sikh"
  | "spiritual"
  | "other";
export type Availability = "yes" | "no" | "waitlist";
export type TherapyType = "inperson" | "telephone" | "videochat" | "textchat";
export type State =
  | "AL"
  | "AK"
  | "AZ"
  | "AR"
  | "CA"
  | "CO"
  | "CT"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "ID"
  | "IL"
  | "IN"
  | "IA"
  | "KS"
  | "KY"
  | "LA"
  | "ME"
  | "MD"
  | "MA"
  | "MI"
  | "MN"
  | "MS"
  | "MO"
  | "MT"
  | "NE"
  | "NV"
  | "NH"
  | "NJ"
  | "NM"
  | "NY"
  | "NC"
  | "ND"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VT"
  | "VA"
  | "WA"
  | "WV"
  | "WI"
  | "WY";
export type Therapy = "individuals" | "couples" | "family" | "group";

export type FaithOption = ListOption<Faith>;
export type EthnicityOption = ListOption<Ethnicity>;
export type TherapyTypeOption = ListOption<TherapyType>;
export type CredentialOption = ListOption<CredentialType>;
export type AgeOption = ListOption<AgeCode>;
export type AvailabilityOption = ListOption<Availability>;
export type StateOption = ListOption<State>;

export const TITLE_OPTIONS: ListOption<Title>[] = ALL_TITLES.map((value) => ({
  value,
  label: `${capitalize(value)}.`,
}));

export const CREDENTIALS_OPTIONS: ListOption<Credential>[] = [
  { value: "ma", label: "MA" },
  { value: "phd", label: "Phd" },
  { value: "lcsw", label: "LCSW" },
];

export const FAITH_OPTIONS: FaithOption[] = [
  { value: "none", label: "No preference" },
  { value: "hindu", label: "Hindu" },
  { value: "christian", label: "Christian" },
  { value: "islam", label: "Islam" },
  { value: "fundamentalist_christian", label: "Fundamentalist Christian" },
  { value: "sikh", label: "Sikh" },
  { value: "jewish", label: "Jewish" },
  { value: "spiritual", label: "Spiritual but not religious" },
  { value: "buddhist", label: "Buddhist" },
  { value: "other", label: "Other" },
  {
    value: "church_of_jesus_christ",
    label: "Church of Jesus Christ of Latter Day Saints",
  },
];
export const ETHNICITY_OPTIONS: EthnicityOption[] = [
  { value: "none", label: "No Preference" },
  { value: "black", label: "Black/African American" },
  { value: "native_american", label: "Native American" },
  { value: "hispanic", label: "Hispanic/Latino" },
  { value: "asian", label: "Asian" },
  { value: "pacific_islander", label: "Pacific Islander" },
  { value: "other", label: "Other Racial or Ethnic Background" },
];

export const THERAPY_TYPE_OPTIONS: TherapyTypeOption[] = [
  { value: "inperson", label: "In-Person" },
  { value: "telephone", label: "Phone" },
  { value: "videochat", label: "Video" },
  { value: "textchat", label: "Chat" },
];

export const VERIFIED_CREDENTIAL_OPTIONS: CredentialOption[] = [
  { value: "diploma", label: "Diploma / Degree" },
  { value: "certificate", label: "Certificate" },
  { value: "license", label: "License" },
  { value: "membership", label: "Membership" },
];

export const AGE_OPTIONS: AgeOption[] = [
  { value: "toddler", label: "Toddler" },
  { value: "child", label: "Child (6-10)" },
  { value: "preteen", label: "Preteen" },
  { value: "teen", label: "Teen" },
  { value: "adult", label: "Adult (18+)" },
  { value: "elder", label: "Senior (65+)" },
];

export const PRIMARY_CREDENTIAL_OPTIONS: ListOption<PrimaryCredentialType>[] = [
  { value: "i_am_licensed", label: "I’m licensed" },
  {
    value: "i_am_pre_licensed",
    label: "I’m pre-licensed or under supervision",
  },
  { value: "i_have_no_license", label: "I have no license" },
];

export const availabilityOptions = {
  in_person_session_availability: "In-Person",
  telephone_session_availability: "Telephone",
  video_session_availability: "Video",
  online_chat_sessions_availability: "ChatConnect",
};

export const mentalHealthRoles = [
  {
    label: "Certified Nurse Practitioner (CNP)",
    value: "Certified Nurse Practitioner (CNP)",
  },
  {
    label: "Clinical Social Worker (CSW)",
    value: "Clinical Social Worker (CSW)",
  },
  {
    label: "Doctor of Psychology (PSYD)",
    value: "Doctor of Psychology (PSYD)",
  },
  {
    label: "Licensed Addiction Counselor (LAC)",
    value: "Licensed Addiction Counselor (LAC)",
  },
  {
    label: "Licensed Clinical Professional Counselor (LCPC)",
    value: "Licensed Clinical Professional Counselor (LCPC)",
  },
  {
    label: "Licensed Clinical Psychologist (LCP)",
    value: "Licensed Clinical Psychologist (LCP)",
  },
  {
    label: "Licensed Clinical Social Worker - Registered (LCSW-R)",
    value: "Licensed Clinical Social Worker - Registered (LCSW-R)",
  },
  {
    label: "Licensed Clinical Social Worker (LCSW)",
    value: "Licensed Clinical Social Worker (LCSW)",
  },
  {
    label: "Licensed Independent Clinical Social Worker (LICSW)",
    value: "Licensed Independent Clinical Social Worker (LICSW)",
  },
  {
    label: "Licensed Marriage and Family Therapist (LMFT)",
    value: "Licensed Marriage and Family Therapist (LMFT)",
  },
  {
    label: "Licensed Master Social Worker (LMSW)",
    value: "Licensed Master Social Worker (LMSW)",
  },
  {
    label: "Licensed Mental Health Counselor (LMHC)",
    value: "Licensed Mental Health Counselor (LMHC)",
  },
  {
    label: "Licensed Nurse Practitioner (LNP)",
    value: "Licensed Nurse Practitioner (LNP)",
  },
  {
    label: "Licensed Professional Counselor - Supervisor (LPC-S)",
    value: "Licensed Professional Counselor - Supervisor (LPC-S)",
  },
  {
    label: "Licensed Professional Counselor (LPC)",
    value: "Licensed Professional Counselor (LPC)",
  },
  {
    label: "Licensed Professional Counselor Candidate (LPCC)",
    value: "Licensed Professional Counselor Candidate (LPCC)",
  },
  {
    label: "Licensed Psychologist (LP)",
    value: "Licensed Psychologist (LP)",
  },
  {
    label: "Limited Licensed Master Social Worker (LLMSW)",
    value: "Limited Licensed Master Social Worker (LLMSW)",
  },
  {
    label: "Limited Licensed Psychologist (LLP)",
    value: "Limited Licensed Psychologist (LLP)",
  },
  {
    label: "Marriage and Family Counselor (MFC)",
    value: "Marriage and Family Counselor (MFC)",
  },
  {
    label: "Medical Doctor (MD)",
    value: "Medical Doctor (MD)",
  },
  {
    label: "National Certified Counselor (NCC)",
    value: "National Certified Counselor (NCC)",
  },
  {
    label: "Professional Counselor",
    value: "Professional Counselor",
  },
  {
    label: "Psychiatric Mental Health Nurse Practitioner (PMHNP)",
    value: "Psychiatric Mental Health Nurse Practitioner (PMHNP)",
  },
  {
    label: "Psychiatrist (PSY-MD)",
    value: "Psychiatrist (PSY-MD)",
  },
  {
    label: "Social Worker",
    value: "Social Worker",
  },
];

export const states = [
  { value: "AL", label: "Alabama" },
  { value: "AR", label: "Arkansas" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DC", label: "District of Columbia" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "IA", label: "Iowa" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "MA", label: "Massachusetts" },
  { value: "MD", label: "Maryland" },
  { value: "ME", label: "Maine" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MO", label: "Missouri" },
  { value: "MS", label: "Mississippi" },
  { value: "MT", label: "Montana" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "NE", label: "Nebraska" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NV", label: "Nevada" },
  { value: "NY", label: "New York" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VA", label: "Virginia" },
  { value: "VT", label: "Vermont" },
  { value: "WA", label: "Washington" },
  { value: "WI", label: "Wisconsin" },
  { value: "WV", label: "West Virginia" },
  { value: "WY", label: "Wyoming" },
];

export const INSURANCE_OPTIONS = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const AVAILABILITY_OPTIONS = [
  { label: "In-person session", value: "in_person_session_availability" },
  { label: "Telephone session", value: "telephone_session_availability" },
  { label: "Video session", value: "video_session_availability" },
  { label: "ChatConnect session", value: "online_chat_sessions_availability" },
];

export const PRICE_OPTIONS = [
  { label: "Intake Session ($0)", value: "0" },
  { label: "$25 - $50", value: "25-50" },
  { label: "$50 - $75", value: "50-75" },
  { label: "$75 - $100", value: "75-100" },
  { label: "$100 - $200", value: "100-200" },
  { label: "> $200", value: "200" },
];

export const DISTANCE_OPTIONS = [
  { label: "5 miles", value: "5" },
  { label: "10 miles", value: "10" },
  { label: "15 miles", value: "15" },
  { label: "25 miles", value: "25" },
  { label: "50 miles", value: "50" },
  { label: "100 miles", value: "100" },
];

export const EXPERIENCE_OPTIONS = [
  { label: "Over 1 year", value: "1" },
  { label: "Over 5 years", value: "5" },
  { label: "Over 10 years", value: "10" },
  { label: "Over 15 years", value: "15" },
  { label: "Over 25 years", value: "25" },
];

export const THERAPY_OPTIONS: ListOption<Therapy>[] = [
  {
    value: "individuals",
    label: "Individuals Therapy",
  },
  { value: "couples", label: "Couples Therapy" },
  { value: "family", label: "Family Therapy" },
  { value: "group", label: "Group Therapy" },
];

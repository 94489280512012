import pdfIcon from "assets/icons/pdf-icon.svg";
import { ReactComponent as IconZoom } from "assets/icons/zoom.svg";
import cn from "classnames";
import { useModal } from "hooks/useModal";
import { FC } from "react";
import { Src } from "types";
import { TherapistFile } from "types/therapist.types";
import styles from "./ImagePreview.module.scss";

interface Props {
  file: TherapistFile | Src;
  alt?: string;
  className?: string;
  withZoom?: boolean;
}

export const ImagePreview: FC<Props> = ({ file, alt, className, withZoom }) => {
  const isPdf =
    ("mime" in file && file.mime?.includes("application")) ||
    file.type?.includes("application");
  const src = "file" in file ? file.file : undefined;
  const viewPdf = () => src && window.open(src, "_blank");
  const { open, addProperties } = useModal("ZOOM_AVATAR");
  const onZoomAvatar = () => {
    const zoomFile = file as TherapistFile;
    addProperties({ file: zoomFile });
    open();
  };

  return (
    <div className={cn(className, styles.root)}>
      <img
        src={isPdf ? pdfIcon : src}
        alt={alt}
        className={cn(isPdf && styles.pdf)}
        onClick={isPdf ? viewPdf : undefined}
        title={isPdf ? "Click to view in new tab" : undefined}
      />
      {withZoom && (
        <button className={styles.icon} type="button" onClick={onZoomAvatar}>
          <IconZoom />
        </button>
      )}
    </div>
  );
};

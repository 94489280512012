import cn from "classnames";
import { useEffect, useRef } from "react";
import { FCWithChildren } from "types/react.types";
import styles from "./Header.module.scss";

interface Props {
  onHeightChange?: (newHeight: number) => void;
  className?: string;
}

export const Header: FCWithChildren<Props> = ({
  onHeightChange,
  className,
  children,
}) => {
  const headerRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (!headerRef.current || !onHeightChange) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      const newHeight = entries[0].target.clientHeight;
      onHeightChange(newHeight);
    });
    resizeObserver.observe(headerRef.current);

    return () => resizeObserver.disconnect();
  }, [onHeightChange]);

  return (
    <header className={cn(className, styles.header)} ref={headerRef}>
      {children}
    </header>
  );
};

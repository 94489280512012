import pauseIcon from "assets/icons/pause.svg";
import playIcon from "assets/icons/play.svg";
import React from "react";
import { VideoData } from "../util";
import styles from "./Controls.module.scss";

function PlayButton({ pausedData }: { pausedData: VideoData<boolean> }) {
  const { val: isPaused, set: setIsPaused } = pausedData;
  return (
    <button
      className={styles.playButton}
      onClick={() => setIsPaused((curr) => !curr)}
    >
      <img src={isPaused ? playIcon : pauseIcon} alt="play-button" />
    </button>
  );
}

export default PlayButton;

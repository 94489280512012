import { AxiosInstance } from "axios";
import { defaultHTTPClient } from "utils/defaultHTTPClient";

export abstract class HttpService {
  protected http: AxiosInstance;

  constructor({ http = defaultHTTPClient } = {}) {
    this.http = http;
  }
}

import { Properties } from "types/modal.types";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";

export type ModalName = "SHARE" | "ZOOM_AVATAR" | "CONTINUE_SURVEY";

class ModalService {
  currentModal: ModalName | null = null;

  properties: Properties = {};

  open(name: ModalName) {
    this.currentModal = name;
  }

  close(name: ModalName) {
    if (name === this.currentModal) this.currentModal = null;
  }

  closeAll() {
    this.currentModal = null;
    this.clearProperties();
  }

  addProperties<T extends Properties>(props: T) {
    this.properties = props;

    return this.properties;
  }

  clearProperties() {
    this.properties = {};

    return this.properties;
  }
}

export const modalService = proxy(new ModalService());
devtools(modalService, { name: "modalService" });

import { FC } from "react";

import styles from "./MapPlaceholder.module.scss";

interface Props {
  text?: string;
}

export const MapPlaceholder: FC<Props> = ({ text }) => {
  return <div className={styles.placeholder}>{text}</div>;
};

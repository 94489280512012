import { useEffect, useState } from "react";

export const useTitle = (defaultTitle = "TheraPass ESA") => {
  const [title, setTitle] = useState(defaultTitle);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return { title, setTitle };
};

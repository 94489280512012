import { useIsMounted } from "hooks/useIsMounted";
import { Dispatch, SetStateAction, useState } from "react";

export const useMountedState = <T>(
  initialState: T
): [T, Dispatch<SetStateAction<T>>] => {
  const isMounted = useIsMounted();
  const [state, setState] = useState<T>(initialState);

  function setStateIfMounted(upd: SetStateAction<T>) {
    if (isMounted.current) {
      setState(upd);
    }
  }

  return [state, setStateIfMounted];
};

import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import cn from "classnames";
import { FC } from "react";
import "./VerifiedCircle.scss";

interface Props {
  text?: string;
  className?: string;
  withoutText?: boolean;
  size?: "sm";
}

export const VerifiedCircle: FC<Props> = ({
  text = "Verified",
  className,
  withoutText,
  size,
}) => {
  return (
    <div
      className={cn(
        "verified-circle",
        className,
        size && `verified-circle--${size}`
      )}
    >
      <div className="verified-circle__icon">
        <CheckIcon />
      </div>
      <span
        className={cn(
          "verified-circle__text",
          withoutText && "verified-circle__text--hidden"
        )}
      >
        {text}
      </span>
    </div>
  );
};

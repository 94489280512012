import { Credential, CredentialId } from "types/credential.types";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";
import { TherapistCollectionService } from "./therapistCollectionService";

class CredentialService extends TherapistCollectionService<
  CredentialId,
  Credential
> {
  constructor(props?: any) {
    super({ ...props, collectionString: "/other-credentials" });
  }
}

export const credentialService = proxy(new CredentialService());

devtools(credentialService, { name: "credentialService" });

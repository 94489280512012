import cn from "classnames";
import { Spinner } from "components/Spinner";
import { FC } from "react";
import "./Preloader.scss";

interface Props {
  text?: string;
  className?: string;
}

export const Preloader: FC<Props> = ({ text, className }) => {
  return (
    <div className={cn("preloader", className)}>
      <Spinner />
      {text}
    </div>
  );
};

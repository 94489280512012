const containsLettersOrNumbers = (value: string) => {
  return /\d|[\wа-яА-Я]/.test(value);
};

export const isEmpty = (value?: any) => {
  return (
    value === undefined ||
    value == null ||
    value?.length <= 0 ||
    (typeof value === "string" && !containsLettersOrNumbers(value))
  );
};

import * as _ from "lodash";
import { useRef } from "react";
import { AbstractFunc } from "types/utils.types";

export const useDelayedFn = (
  fn: AbstractFunc,
  updRate: number,
  delayer: "throttle" | "debounce",
  leading = true
) => {
  const delayedFnRef = useRef(_[delayer](fn, updRate, { leading }));

  return (...args: any) => {
    delayedFnRef.current.cancel();
    delayedFnRef.current(...args);
  };
};

import { mapOptions } from "constants/googleMap";
import { useJsApiLoader } from "@react-google-maps/api";
import { useResponsive } from "hooks/useResponsive";
import {
  ChangeEventHandler,
  FocusEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

type Option = {
  id: string;
  text: string;
};

const removeCountryName = (string: string) => string.replace(", USA", "");

export const useInputSmartSearch = (
  propValue?: string,
  onSelectPlace?: (args: {
    search?: string;
    city?: string;
    state?: string;
    zip?: string;
    address?: string;
    latitude?: number;
    longitude?: number;
  }) => void,
  types: string[] = [
    "postal_code",
    "locality",
    "administrative_area_level_1",
    "administrative_area_level_3",
  ]
) => {
  const isMobile = useResponsive("sm");
  const [options, setOptions] = useState<Option[]>([]);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [maxOptionsHeight, setMaxOptionsHeight] = useState<number>();
  const [value, setValue] = useState("");
  const [isSelectOption, setIsSelectOption] = useState(false);
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const autocomplete = useRef<google.maps.places.AutocompleteService>();
  const geocoder = useRef<google.maps.Geocoder>();

  const { isLoaded } = useJsApiLoader(mapOptions);

  useEffect(() => {
    setValue(propValue || "");
  }, [propValue]);

  useLayoutEffect(() => {
    if (optionsContainerRef.current) {
      const containerRect = optionsContainerRef.current.getBoundingClientRect();
      const bodyHeight = document.body.offsetHeight;
      const optionsContainerTop = window.scrollY + containerRect.top;

      setMaxOptionsHeight(
        isMobile
          ? undefined
          : document.body.getBoundingClientRect().bottom -
              optionsContainerTop -
              50
      );

      const bottomPadding = 20;
      const newBodyHeight =
        window.scrollY + containerRect.bottom + bottomPadding;
      if (isMobile && bodyHeight < newBodyHeight) {
        document.body.style.height = `${newBodyHeight}px`;
      }

      const newScrollHeight =
        window.scrollY + containerRect.bottom - window.innerHeight;
      if (containerRect.bottom > window.innerHeight) {
        window.scrollTo(0, newScrollHeight);
      }
    }
  }, [options, isOptionsVisible, isMobile]);

  useEffect(() => {
    try {
      if (
        window.google &&
        window.google.maps.places.PlacesServiceStatus.OK === "OK"
      ) {
        autocomplete.current =
          new window.google.maps.places.AutocompleteService();
        geocoder.current = new window.google.maps.Geocoder();
      }
    } catch (error) {
      console.error(error);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (value) {
      const debounceFn = setTimeout(() => {
        if (onSelectPlace && value === "") {
          return onSelectPlace({ search: "" });
        }
        if (onSelectPlace && !isSelectOption && !propValue) {
          return onSelectPlace({ search: value, city: value });
        }
      }, 300);

      return () => clearTimeout(debounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const fetchOptions = useCallback(async (input: string) => {
    if (autocomplete.current && input.length > 1) {
      const results = await autocomplete.current.getPlacePredictions({
        input,
        types: [...types],
        componentRestrictions: { country: "us" },
      });

      setOptions(
        results.predictions.map((place) => ({
          id: place.place_id,
          text: removeCountryName(place.description),
        }))
      );
    } else {
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = useCallback(async (placeId: string) => {
    setIsSelectOption(true);
    if (geocoder.current) {
      const response = await geocoder.current.geocode({
        placeId,
      });

      const result = response.results[0];

      let city: google.maps.GeocoderAddressComponent | undefined;
      let state: google.maps.GeocoderAddressComponent | undefined;
      let zip: google.maps.GeocoderAddressComponent | undefined;

      result.address_components.forEach((component) => {
        if (
          component.types.includes("administrative_area_level_3") ||
          component.types.includes("locality")
        )
          city = component;
        if (component.types.includes("administrative_area_level_1"))
          state = component;
        if (component.types.includes("postal_code")) zip = component;
      });

      const addressWithoutCountry = removeCountryName(result.formatted_address);

      const street = result.address_components.find((component) =>
        component.types.includes("route")
      )?.long_name;
      const { geometry } = result;
      const streetNumber = result.address_components.find((component) =>
        component.types.includes("street_number")
      )?.long_name;
      const subpremise = result.address_components.find((component) =>
        component.types.includes("subpremise")
      )?.long_name;
      const house = [subpremise, streetNumber].filter(Boolean).join("/");
      setValue(addressWithoutCountry);
      setIsOptionsVisible(false);

      if (onSelectPlace) {
        onSelectPlace({
          search: addressWithoutCountry,
          city: city?.long_name,
          state: state?.short_name,
          zip: zip?.long_name,
          address: [house, street].filter(Boolean).join(" "),
          latitude: geometry?.location?.lat() ? geometry?.location?.lat() : 0,
          longitude: geometry?.location?.lng() ? geometry?.location?.lng() : 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (value !== " ") {
      setIsSelectOption(false);
      setValue(event.currentTarget.value);
      fetchOptions(event.currentTarget.value);
    }
  };

  const handleFocus: FocusEventHandler = () => {
    setIsOptionsVisible(true);
  };

  const handleBlur: FocusEventHandler = () => {
    setIsOptionsVisible(false);
  };

  return {
    value,
    options,
    isLoaded,
    isOptionsVisible,
    optionsContainerRef,
    maxOptionsHeight,
    handleSelect,
    handleChange,
    handleFocus,
    handleBlur,
    isSelectOption,
  };
};

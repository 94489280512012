import { FormatProfileField } from "components/FormatProfileField";
import { FCWithChildren } from "types/react.types";

interface Props {
  label: string;
  isVisible?: boolean;
}

export const TabItemBlock: FCWithChildren<Props> = ({
  children,
  label,
  isVisible,
}) => {
  return children || isVisible ? (
    <p className="patients-therapist-tabs__subject">
      {label}{" "}
      <FormatProfileField shouldLimitLength={false}>
        {children}
      </FormatProfileField>
    </p>
  ) : null;
};

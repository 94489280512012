import cn from "classnames";
import { useResponsive } from "hooks/useResponsive";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { FCWithChildren } from "types/react.types";
import styles from "./Modal.module.scss";

export interface Props {
  visible: boolean;
  className?: string;
  transition?: boolean;
  duration?: number;
  backdrop?: boolean | "static";
  onShow?: () => void;
  onClose?: () => void;
  fadePercentage?: number;
}

const maxBgOpacity = 0.3;
const minBgOpacity = 0.1;

export const Modal: FCWithChildren<Props> = ({
  children,
  visible,
  className,
  onClose,
  fadePercentage = 0,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_visible, setVisible] = useState(visible);
  const isMobile = useResponsive("layout-flip");

  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  const handleClickOutside = useCallback(
    (event: Event) => {
      if (event.target === modalRef.current) {
        onClose?.();
      }
    },
    [onClose]
  );

  useEffect(() => {
    const currentRef = modalRef.current;
    if (!currentRef) {
      return;
    }

    currentRef.addEventListener("click", handleClickOutside);

    return () => {
      currentRef.removeEventListener("click", handleClickOutside);
    };
  }, [_visible, handleClickOutside]);

  useLayoutEffect(() => {
    const { body } = document;
    const scrollWidth = window.innerWidth - body.clientWidth;

    if (_visible) {
      body.style.overflow = "hidden";
      body.style.paddingRight = `${scrollWidth}px`;
      setTimeout(() => {
        modalRef.current?.focus();
      });
    } else {
      body.style.overflow = "auto";
      body.style.paddingRight = "0px";
    }
    return () => {
      body.style.overflow = "0px";
    };
  }, [_visible]);

  const backgroundOpacity =
    maxBgOpacity * (1 - fadePercentage) + minBgOpacity * fadePercentage;
  const backgroundColor = isMobile ? "0, 0, 0" : "31, 52, 47";

  return _visible ? (
    <div
      className={cn(styles.popupContainer, className)}
      style={{ background: `rgba(${backgroundColor}, ${backgroundOpacity})` }}
      tabIndex={-1}
      role="dialog"
      ref={modalRef}
    >
      {children}
    </div>
  ) : null;
};

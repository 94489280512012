import { FC, ElementType } from "react";
import "./AgeCard.scss";

interface Props {
  label: string;
  Icon: ElementType;
  age: string;
}

export const AgeCard: FC<Props> = ({ label, Icon, age }) => {
  return (
    <div className="age-card">
      <Icon className="age-card__icon" />
      <p className="age-card__title">{label}</p>
      <p className="age-card__age">{age}</p>
    </div>
  );
};

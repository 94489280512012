import cn from "classnames";
import { Spinner } from "components/Spinner";
import { useEffect, useRef } from "react";
import { FCWithChildren } from "types/react.types";
import styles from "./LoadingHOC.module.scss";

interface Props {
  isLoading?: boolean;
  className?: string;
  text?: string;
}

const messageMargin = 64;

export const LoadingHOC: FCWithChildren<Props> = ({
  isLoading = false,
  className,
  children,
  text,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollHandler = () => {
      if (messageRef.current && overlayRef.current) {
        const overlayRect = overlayRef.current.getBoundingClientRect();
        const top = window.innerHeight / 2 - overlayRect.top;
        messageRef.current.style.top = `${Math.max(top, messageMargin)}px`;
      }
    };

    document.addEventListener("scroll", scrollHandler);

    return () => document.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <div className={cn(styles.loadingContainer, className)}>
      {children}
      {isLoading && (
        <div className={styles.overlay} ref={overlayRef}>
          {text && (
            <div className={styles.loadingMessage} ref={messageRef}>
              <Spinner className={styles.spinner} /> <p>{text}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

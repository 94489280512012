import { Dispatch, SetStateAction } from "react";

export type VideoData<T> = {
  val: T;
  set: Dispatch<SetStateAction<T>>;
};

const UPD_RATE = 250;

const SEEK_INTERVAL = 0.05;
const MIN_SHIFT = 1;

function calcSeekedTime(
  totalDuration: number | null,
  currentTime: number,
  forward = true,
  seekInterval = SEEK_INTERVAL,
  minShift = MIN_SHIFT
) {
  if (totalDuration == null) {
    return currentTime;
  }

  const shift = Math.max(minShift, totalDuration * seekInterval);
  return forward
    ? Math.min(totalDuration, currentTime + shift)
    : Math.max(0, currentTime - shift);
}

export { calcSeekedTime, UPD_RATE };
